<!-- <div [@openClose]="isVisible ? 'open' : 'closed'" class="info-container">
  <div class="info-location">

    <div class="arrows" *ngIf="platforms.length > 1">
      <button class="next-button" (click)="previous()">ᐸ</button>
      <span class="platform-index">{{selectedIndex + 1}} of {{platforms.length}}</span>
      <button class="next-button" (click)="next()">ᐳ</button>

    </div>
    <div class="info-close" (click)="close()">✕</div>

    <div *ngIf="isLoading" class="loader"></div>
  </div>
  <div class="divider" *ngIf="platforms.length > 1"></div>
  <div *ngIf="!platform" class="label">No platform available</div>
  <div *ngIf="platform">
    <div class="label">Platform: <span class="value-label">{{platform.properties.platformcode}}</span></div>
    <div class="label">Type: <span class="value-label">{{platform.properties.platformtypedescr}}</span></div>
    <div class="label">Provider: <span class="value-label">{{platform.properties.dataownerdescr}}</span></div>
    <div class="label">Country: <span class="value-label">{{platform.properties.dataownercountry}}</span></div>
    <div class="label">Last Data: <span class="value-label">{{platform.properties.lastdatameasured}}</span></div>
    <div class="label">Parameters: <span class="value-label">{{platform.properties.parametersgroupdescr}}</span></div>
    <div class="label">Lat: <span class="value-label">{{platform.properties.latitude}}</span> Lng: <span class="value-label">{{platform.properties.longitude}}</span></div>
  </div>
  <div *ngIf="platform" class="divider"></div>
  <a *ngIf="platform" class="plot-chart-button" href="https://www.emodnet-physics.eu/map/platinfo/piroosplot.aspx?platformid={{platform.properties.platformid}}" target="_blank">Open Platform Page</a>
</div>
 -->

<div id="container" class="container">
  <div class="header">
    <div></div>
    <div class="navigation" *ngIf="loading || iframeArray.length == 0"></div>
    <div class="navigation" *ngIf="!loading && iframeArray.length > 0">
      <select name="select" id="selectorIframe" (change)="selectNewIframe($event.target)">
        <option value="{{item.index}}" *ngFor="let item of this.iframeArray" >{{item.name}}</option>
      </select>
      <!-- <button class="arrows" (click)="navigate(false)">ᐸ&nbsp;</button>

      <span>
        {{ this.currentIndex + 1 }}
        of
        {{ this.iframeArray.length }}
      </span>
      <button class="arrows" (click)="navigate(true)">&nbsp;ᐳ</button> -->

    </div>
    <div class="close" (click)="close()">
      <button class="close-button">
        ✕
      </button>
    </div>
  </div>
  <div *ngIf="loading" class="body-load">
    <div class="loader big"></div>
  </div>
    <!-- <div class="divider"></div> -->
     <div class="content" id="content" *ngIf="iframeArray.length > 0 && !loading">
        <iframe height="90%" width="100%" id="iframe" [src]="iframeArray[currentIndex].iframe | safe"></iframe>
     </div>
    <!-- <div class="content" *ngIf="currentFeatureInfo">
      <div class="label">
        Platform:
        <span class="value-label">{{
          currentFeatureInfo.properties?.PLATFORMCODE
        }}</span>
      </div>
      <div class="label">
        Type:
        <span class="value-label">{{
          currentFeatureInfo.properties?.platform_type_longname
        }}</span>
      </div>
      <div class="label">
        Provider:
        <span class="value-label">{{
          currentFeatureInfo.properties?.data_owner_longname
        }}</span>
      </div>
      <div class="label">
        Country:
        <span class="value-label">{{
          currentFeatureInfo.properties?.data_owner_country_code
        }}</span>
      </div>
      <div class="label">
        Last Data:
        <span class="value-label">{{
          currentFeatureInfo.properties?.lastDateObservation
        }}</span>
      </div>
      <div class="label">
        Parameters:
        <span class="value-label">{{
          this.parameters_group_longname_sanitized
        }}</span>
      </div>
      <div class="label">
        Lat: <span class="value-label">{{ lat }}</span> Lng:
        <span class="value-label">{{ lng }}</span>
      </div>
    </div>
    <div class="content label" *ngIf="!currentFeatureInfo">
      No platform available
    </div>
    <div class="divider"></div> -->
    <div class="image-resize-container">
      <img (pointerdown)="resizeElement()" id="resizeIcon" style="transform: rotate(45deg); cursor: nwse-resize;" src="./assets/icons/resize_icon.svg" alt="">
    </div>

  <!-- <div class="footer" *ngIf="featureInfo.length > 0 && currentFeatureInfo">
    <a class="plot-chart-button" href="https://www.emodnet-physics.eu/map/platinfo/piroosplot.aspx?platformid={{currentFeatureInfo.properties?.platformid}}" target="_blank">Open Platform Page</a>
    <a *ngIf="!loading"
      class="plot-chart-button"
      href="https://www.emodnet-physics.eu/Map/spi.aspx?id={{currentFeatureInfo.properties?._id}}"
      target="_blank"
      >Download Platform</a
    >
  </div> -->
</div>
