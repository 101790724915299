import {
  Coords,
  DoneCallback,
  LeafletMouseEvent,
  Point,
  TileLayer,
} from 'leaflet';
import { forkJoin, from, Observable, Subject, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { FeatureInfoParamsOptions } from './interfaces/feature-info-params-options';
import { FeatureInfoParamsSettings } from './interfaces/feature-info-params-settings';
import { OverlayLayer } from './interfaces/overlay-layer';
import { Map } from './map';
import 'projects/shared/src/public-api';
import { FeatureInfo, FeatureInfoRootObject } from './interfaces/feature-info';
import { PlatformHits } from './interfaces/platform-hits';
import { PlatformInfo } from './interfaces/platform-info';
import { SharedData } from './core/shared-data';
import { HttpClient } from '@angular/common/http';
import { FeatureInfoPoint } from './interfaces/feature-info-point';
import { PlatformsResponse } from './interfaces/platforms-response';

export class WMSTileLayer extends TileLayer.WMS {
  httpClient: HttpClient = SharedData.getInstance().httpClient;
  onMap: boolean = false;
  wmsLayerOptions?: OverlayLayer;
  platformHitsOptions?: PlatformHits;
  platformsInfoSubscription: Subscription | null = null;
  tilesSubscriptions: Subscription[] = [];
  loading: Subject<boolean> = new Subject<boolean>();
  featureInfoLoading: Subject<boolean> = new Subject<boolean>();
  platformsInfoLoading: Subject<boolean> = new Subject<boolean>();

  featureInfoChanged: Subject<FeatureInfoPoint> = new Subject<FeatureInfoPoint>();
  platformsInfoChanged: Subject<PlatformInfo[]> = new Subject<PlatformInfo[]>();
  private controlCql_filter: string = '';
  currentCqlFilter: string = '';
  currentTimeFilter: string = '';
  wmsCqlFilter: string = '';
  constructor(wmsLayerOptions: OverlayLayer, map: Map) {
    super(wmsLayerOptions.url, wmsLayerOptions);
    this.wmsLayerOptions = wmsLayerOptions;
    const timeout = setTimeout(() => {
      this.loading.next(true);
      clearTimeout(timeout);
    }, 100);

    this.on('loading', () => {
      this.loading.next(true);
    });

    this.on('load', () => {
      this.getPlatformsInfo();
      this.loading.next(false);
    });

    map.on('zoomstart', () => {
      this.clearTilesSubscription();
    });

  }

  private getPlatformsInfo() {

    const baseCql_filter: string = (this.options as any).cql_filter;
    if (this.controlCql_filter === '') {
      this.controlCql_filter = baseCql_filter + '';
    }
    // const baseParams = {
    //   request: this.platformHitsOptions?.request,
    //   service: this.platformHitsOptions?.service,
    //   version: this.platformHitsOptions?.version,
    //   typeName: this.platformHitsOptions?.typeName,
    //   resultType: this.platformHitsOptions?.resultType,
    // };



    if (this.platformHitsOptions && this.platformHitsOptions.url && this.currentTimeFilter !== '') {
      const obs = this.httpClient
        .get<PlatformsResponse[]>(this.platformHitsOptions.url as string, {
          params: {
            cql_filter: `${this.controlCql_filter}`,
            TIME: this.currentTimeFilter,
            layerTypeName: this.platformHitsOptions.layerTypeName
            // ...baseParams,
          } as any,
        })
        .pipe(
          map((m) => {

            return this.platformHitsOptions?.layerFilterCategory?.filters?.map(x => {
              //var text = x.value as string;
              //const regex = /[0-9][0-9]|[0-9]/g;
              //const found = text.match(regex);
              return {
                //platformNumber: m.find(f => f.platformtypeid == +(found as string[])[0])?.numberMatched,
                platformNumber: m.find(f => f.platformtypeCode == x.code)?.numberMatched,
                name: x.categoryName,
                iconUrl: x.iconUrl,
              } as PlatformInfo;
            });
          })
        );

      this.platformsInfoSubscription = obs.subscribe((x) => {
        this.platformsInfoChanged.next(x);
        this.platformsInfoLoading.next(false);

        this.platformsInfoSubscription?.unsubscribe();
        this.platformsInfoSubscription = null;
      });
    }

  }

  private clearTilesSubscription() {
    this.tilesSubscriptions?.forEach(x => x.unsubscribe());
    this.tilesSubscriptions = [];
  }

  // updateFilters(filter: string, timeFilter: string) {
  //   this.platformsInfoSubscription?.unsubscribe();
  //   this.platformsInfoSubscription = null;
  //   this.clearTilesSubscription();
  //   this.platformsInfoLoading.next(true);

  //   const text = this.wmsLayerOptions?.cql_filter
  //     ? `${this.wmsLayerOptions?.cql_filter} ${filter ? ' AND ' + filter : ''}`
  //     : filter;
  //   this.currentCqlFilter = text;
  //   this.currentTimeFilter = timeFilter;


  //   (this.options as any).cql_filter = text;
  //   // (this.options as any).time = timeFilter;

  //   this.setParams({ TIME: timeFilter, cql_filter: text } as any);
  // }

  updateFilters(filter: string, timeFilter: string = '') {
    this.platformsInfoSubscription?.unsubscribe();
    this.platformsInfoSubscription = null;
    this.clearTilesSubscription();
    this.platformsInfoLoading.next(true);
    const text = this.wmsLayerOptions?.cql_filter
      ? `${this.wmsLayerOptions?.cql_filter} ${filter ? ' AND ' + filter : ''}`
      : filter;
    this.currentCqlFilter = text;
    this.currentTimeFilter = timeFilter;
    (this.options as any).cql_filter = text;
    //console.log('filter',text)
    this.setParams({ TIME: timeFilter, cql_filter: text } as any);

  }

  setFeatureInfo(
    leafletMap: Map,
    event: LeafletMouseEvent,
    settings?: FeatureInfoParamsSettings
  ) {
    this.featureInfoLoading.next(true);
    const point = event.containerPoint;
    const layerOptions = this.wmsLayerOptions;
    if ((!layerOptions?.featureInfo && !layerOptions) || !settings) return;

    const size = leafletMap.getSize();
    const options: FeatureInfoParamsOptions = {
      width: size.x,
      height: size.y,
      bbox: settings?.create_bbox(
        settings.wmsVersion,
        leafletMap.getBounds(),
        leafletMap.options.crs
      ),
      service: settings.service,
      version: settings.version,
      layers: layerOptions.layers ?? '',
      format: settings.format,
      transparent: settings.transparent,
      request: settings.request,
      query_layers: layerOptions.layers ?? '',
      X: Math.round(point.x),
      Y: Math.round(point.y),
      INFO_FORMAT: settings.INFO_FORMAT,
      [settings.create_projection_key(settings.wmsVersion)]: leafletMap.options
        ?.crs?.code,
      tiled: settings.tiled,
      feature_count: settings.feature_count,
      styles: settings.styles ?? '',
      cql_filter: this.currentCqlFilter,
      TIME: this.currentTimeFilter
    };
    const zoom = leafletMap.getZoom()
    const subs = this.httpClient
      .post<FeatureInfoRootObject>(
        layerOptions.url,
        new URLSearchParams(options).toString(),
        { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
      )
      .pipe(
        map((x) => {
          if (x.numberReturned > 1 && zoom < 11) {
            return []
          } else {
            return x.features;
          }
        })
      )
      .subscribe((x) => {
        if (x?.length > 0 || zoom >= 11) {
          this.featureInfoChanged.next({ latLng: event.latlng, featureInfo: x });
        } else {
          this.featureInfoChanged.next({ latLng: event.latlng, featureInfo: [], zoom:'decrease' });
        }
        this.featureInfoLoading.next(false);
      });
  }

  createTile(coords: Coords, done: DoneCallback): HTMLElement {
    const img = document.createElement('img');
    this.loadTile(coords, done, img);
    return img;
  }

  private loadTile(coords: Coords, done: DoneCallback, img: HTMLImageElement) {
    const [url, urlparams] = this.getTileUrl(coords).split('?');
    const fileReader = new FileReader();
    this.tilesSubscriptions?.push(this.httpClient
      .post<Blob>(url, urlparams, {
        responseType: 'blob' as any,
        headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      })
      .subscribe((x) => {
        fileReader.onload = () => {
          img.src = fileReader.result as string;
          done(undefined, img);
        };
        fileReader.readAsDataURL(x);
      }));
  }


  // updateCqlFilter(cqlFilter:any,filter:any){
  //   this.controlCql_filter = '(1=1) AND ' + cqlFilter;
  //   this.updateFilters(filter.filter, filter.time)
  //   this.getPlatformsInfo();
  // }
}
