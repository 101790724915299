import { HttpClient } from '@angular/common/http';
import { Component, ComponentFactory, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Map } from 'projects/leaflet/src/public-api';
import { IFilter } from 'projects/shared/src/core/filter.inerface';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'emodnet-fe-filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss']
})
export class FiltersDialogComponent implements OnInit {
  @ViewChild('componentPlaceholder', { read: ViewContainerRef }) public componentPlaceholder?: ViewContainerRef;

  filters: IFilter[] =  [];
  factories: ComponentFactory<IFilter>[] = [];
  public errorText: string = '';
  constructor(
    private dialogRef: MatDialogRef<FiltersDialogComponent>,
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: { map: Map }
    ) { }

  ngOnInit(): void {
    forkJoin([
      import('projects/date-range-filter/src/public-api')
    ]).subscribe(x => {
      x.forEach((f, i) =>{
        const factory = this.resolver.resolveComponentFactory<IFilter>(f.FilterComponent);
        this.factories.push(factory);
        this.componentPlaceholder?.createComponent(factory);
/*         const ref = this.componentPlaceholder?.createComponent(factory);
        if (ref?.instance) {
          if (i == 0) {
            ref.instance.selected = true;
          }
          ref.instance.map = this.data.map;
          this.filters.push(ref.instance);
        } */
      })
    })
  }
  addFilter(filter: IFilter) {
    this.filters.push(filter);
  }
  setFilters() {
    const filter = this.filters[0].getFilter();
    if(filter && filter.error){
      this.errorText = filter.error.response[0];
    } else {
      this.dialogRef.close(this.filters.map(x => filter.value).filter(x => x).join(' AND '));
    }
  }
  close() {
    this.dialogRef.close();
  }

}
